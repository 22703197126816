import { call, put, all } from "redux-saga/effects"
import { SET_NEW_SECTION, SET_ALL_CONTENT } from "../actions"
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
function getContent() {
    return new Promise((resolve, reject) => {
        firebase.database().ref('/').once('value').then((snapshot) => {
            snapshot.val() ? resolve(snapshot.val()) : reject("Error getting data from database")
        })
    })
}
const uploadImage = data => {
    return new Promise((resolve, reject) => {
        const { editingPage, editingSection, newImage } = data.update
        var uploadTask = firebase.storage().ref().child(`/${editingPage}/${editingSection}/${newImage.name}`).put(data.update.newImage)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    default:
                }
            }, error => {
                reject(error)
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    resolve(downloadURL)
                });
            });

    })
}
const pushUpdateSection = (page, section, data) => {
    return new Promise((resolve, reject) => {
        firebase.database().ref(`content/pages/${page}/${section}`).set(data, (error) => {
            error ? reject(error) : resolve("success")
        });
    })
}
const callUpdateReducer = () => ({
    type: SET_NEW_SECTION,
    error: false,
    updated: true,
    success: true,
})
const callMainReducer = (response) => ({
    type: SET_ALL_CONTENT,
    response: response,
    error: false,
})


export function* updateSection(action) {
    try {
        const uploadURL = action.update.newImage ? yield call(uploadImage, action) : false
        const { editingSectionData, editingPage, editingSection } = action.update
        Object.assign(editingSectionData, { "image": uploadURL ? uploadURL : editingSectionData.image })
        yield call(pushUpdateSection, editingPage, editingSection, editingSectionData);
        const contentResponse = yield call(getContent)
        yield all([
            put(callUpdateReducer()),
            put(callMainReducer(contentResponse))
        ])
    }
    catch (error) {
        console.log("got error updating...", error)
        yield put({
            type: SET_NEW_SECTION,
            error: false,
            updated: false,
            success: false,
        })
    }
}
export function* resetUpdateReducer(action) {
    console.log("resetting update reducer")
    try {
        yield put({
            type: SET_NEW_SECTION,
            error: false,
            updated: false,
            success: false,
        })
    }
    catch (error) {
        console.log("got error updating...", error)
        yield put({
            type: SET_NEW_SECTION,
            error: false,
            updated: false,
            success: false,
        })
    }
}
