import { SET_REFRESH_TOKEN, SET_LOGIN_ERROR, SET_LOG_OUT_USER } from "../actions"
const initialUserState = {
    submitted: false,
    response: null,
    isLoggedIn: false
}
const initialErrorState = {
    submitted: false,
    response: null,
}
export const loginReducer = (state = initialUserState, action) => {
    if (action.type === SET_REFRESH_TOKEN) {
        console.log("logged in...", action)
        state = {
            ...state,
            submitted: true,
            response: action,
            isLoggedIn: action.success
        }
    } else if (action.type === SET_LOG_OUT_USER) {
        localStorage.clear()
        state = {
            ...state,
            ...action
        }
    }
    return state
}
export const loginError = (state = initialErrorState, action) => {
    if (action.type === SET_LOGIN_ERROR) {
        console.log("Log in error", action)
        state = {
            ...state,
            submitted: true,
            response: action,
        }
    }
    return state
}
