import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAllContent } from "../state/actions/MainActions"

import MainApp from '../components/DashboardLayout/DashboardLayout'

class DashboardContainer extends Component {
    componentDidMount() {
        this.props.getAllContent()
    }
    UNSAFE_componentWillReceiveProps = (prevProps) => {

    }
    render() {
        // console.log("state updated?", this.props.state.mainReducer.updated)
        return (
            <MainApp>
                {this.props.children}
            </MainApp>
        )
    }
}

const mapStateToProps = state => ({
    state
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getAllContent
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)