import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage'
import { loginReducer, loginError } from "./AuthReducer"
import { mainReducer } from "./MainReducer"
import { updateReducer } from "./UpdatedReducer.js"

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: ['loginError', 'updateReducer']
}

const reducers = combineReducers({
    loginReducer, loginError, mainReducer, updateReducer
})

export default persistReducer(rootPersistConfig, reducers)