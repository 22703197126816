import { GET_ALL_CONTENT, GET_NEW_SECTION, GET_RESET_UPDATE_REDUCER } from "."

export const getAllContent = values => {
    // console.log("Get all content")
    return {
        type: GET_ALL_CONTENT,
    }
}
export const updateSection = update => {
    return {
        type: GET_NEW_SECTION,
        update
    }
}
export const resetUpdateReducer = () => {
    return {
        type: GET_RESET_UPDATE_REDUCER,
    }
}
