import { SET_ALL_CONTENT } from "../actions"
const initialState = {
    error: false,
    response: null,
    submitted: false,
    success: false,
    updated: false,
}

export const mainReducer = (state = initialState, action) => {
    if (action.type === SET_ALL_CONTENT) {
        // console.log("fetched new data")
        state = {
            ...state,
            ...action,

        }
    }
    return state
}
