import { SET_NEW_SECTION } from "../actions"
const initialState = {
    error: false,
    updated: false,
    success: false,
}
export const updateReducer = (state = initialState, action) => {
    if (action.type === SET_NEW_SECTION) {
        // console.log("update reducer ?", action)
        state = {
            ...state,
            ...action,
        }
    }
    return state
}
