import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Spin } from 'antd';
import { List } from 'antd';
import { Button, } from 'antd';
import { Form, Upload, Input, message, Card, } from 'antd';
import { updateSection, resetUpdateReducer } from "../../state/actions/MainActions"
// import InfiniteScroll from 'react-infinite-scroller';
const { Meta } = Card
class Page_Content extends Component {
    _isMounted = false;
    constructor() {
        super()
        this.state = {
            editMode: false,
            editingPage: null,
            editingSection: null,
            editingSectionData: null,
            newImage: null,
            loadingNewImage: false,
            sendingData: false

        }
    }
    editSection = (page, section) => {
        const currentPage = this.props.page
        this.props.resetUpdateReducer()
        this.setState({
            editMode: true,
            editingPage: page,
            editingSection: section,
            editingSectionData: currentPage[section],
        });

    }
    cancelEditSection = () => {
        console.log('reset it now')
        this.props.resetUpdateReducer()
        this.setState({
            editMode: false,
            editingPage: null,
            editingSection: null,
            editingSectionData: null,
            newImage: null,
            sendingData: false
        });
    }
    componentDidMount = () => {
        this._isMounted = true;
    }

    UNSAFE_componentWillReceiveProps = (prevProps) => {
        const { currentTab } = this.props
        const { updated } = this.props.state.updateReducer
        if (currentTab !== prevProps.currentTab) {
            console.log("tab changed")
            this.cancelEditSection()
        }
        if (updated) {
            this.cancelEditSection()
        }

    }
    handleSubmit = () => {
        const { editingSectionData, newImage, editingSection, editingPage } = this.state
        const payload = {
            editingSectionData,
            newImage,
            editingPage,
            editingSection
        }
        this.setState({ sendingData: true })
        this.props.updateSection(payload)
    };
    onChangeInput = (e, input) => {
        const { value } = e.target;
        const { editingSectionData } = this.state
        let newEditData = Object.assign({}, editingSectionData);
        newEditData[input] = value
        this.setState({
            editingSectionData: newEditData
        })
    }
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error('Image must smaller than 10MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    handleChangeImage = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loadingNewImage: true });
            return;
        }
        if (info.file.status === 'done') {
            this.getBase64(info.file.originFileObj, imageUrl => {
                var newImage = info.file.originFileObj
                const { editingSectionData } = this.state
                let newEditData = Object.assign({}, editingSectionData);
                newEditData.image = imageUrl
                return this.setState({
                    newImage,
                    editingSectionData: newEditData,
                    loadingNewImage: false
                })
            }
            );
        }
    };


    render() {
        const currentPage = this.props.page
        const { editingSectionData, loadingNewImage, editMode, editingSection, sendingData } = this.state
        if (!currentPage) {
            return <div className="loading_div">
                <Spin />
            </div>
        }
        const allSections = []
        Object.keys(currentPage).map((val) => {
            if (val !== "id" && val !== "order") {
                allSections.push({ ...currentPage[val], section_name: val })
            }
            return allSections
        })

        const allSectionsSorted = allSections.sort((a, b) => (a.id > b.id) ? 1 : -1)
        return (
            <div className="pages_content" >

                <List
                    dataSource={allSectionsSorted}
                    renderItem={item => (
                        <Form className="editForm"  >
                            <List.Item
                                actions={
                                    (editMode && editingSection === item.section_name) ? [
                                        <Button onClick={() => this.cancelEditSection()} type="primary" size="small">
                                            Cancel
                                 </Button>,
                                        <Button loading={sendingData} onClick={this.handleSubmit}
                                            disabled={editMode ? editingSection === item.section_name ? loadingNewImage ? true : false : true : loadingNewImage ? true : false}
                                            type="primary" size="small">
                                            Save
                                    </Button>] :
                                        [<Button disabled={editMode ? editingSection === item.section_name ? false : true : false} onClick={() => this.editSection(currentPage.id, item.section_name)} type="primary" size="small">
                                            {editingSection === item.section_name ? "Save" : "Edit"}
                                        </Button>]
                                }
                            >
                                <List.Item.Meta
                                    avatar={
                                        (editingSection === item.section_name) ?
                                            <Upload
                                                disabled={item.image === "http://view.dreamstalk.ca/breeze5/images/no-photo.png" ? true : false}
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleChangeImage}
                                            >
                                                {loadingNewImage ? <div className="loading_div">
                                                    <Spin />
                                                </div> :
                                                    <div className="section_image_card">
                                                        <div className="list_image_div" style={{ backgroundImage: `url(${editingSectionData.image})` }} />
                                                    </div>
                                                }

                                            </Upload> :
                                            <Card
                                                bordered={false}
                                                className="section_image_card"
                                                cover={<div className="list_image_div" style={{ backgroundImage: `url(${item.image})` }} />}
                                            >
                                                <Meta description={<center>{item.name}</center>} />
                                            </Card>

                                    }
                                    description={
                                        (item.id >= 3 && currentPage.id === "neighborhood") ?
                                            item.id === 11 ? <div /> : <div>{(item.id >= 3 && item.id <= 5) ? "Left" : "Right"} Column images</div>
                                            :
                                            <div>
                                                {item.header_one === "NULL" ? null :
                                                    editingSection === item.section_name ?
                                                        <Form.Item >
                                                            <Input size="small" addonBefore="First Header:" onChange={(e) => this.onChangeInput(e, "header_one")} value={editingSectionData.header_one} />
                                                        </Form.Item>

                                                        :
                                                        <p><span>First Header:</span>{item.header_one}</p>
                                                }
                                                {item.header_two === "NULL" ? null :
                                                    editingSection === item.section_name ?
                                                        <Form.Item >
                                                            <Input size="small" addonBefore="Second Header:" onChange={(e) => this.onChangeInput(e, "header_two")} value={editingSectionData.header_two} />
                                                        </Form.Item>
                                                        :
                                                        < p > <span>Second Header:</span>{item.header_two}</p>
                                                }
                                                {item.paragraph === "NULL" ? null :
                                                    editingSection === item.section_name ?
                                                        <Form.Item >
                                                            <Input
                                                                size="small" addonBefore="Paragraph:" onChange={(e) => this.onChangeInput(e, "paragraph")} value={editingSectionData.paragraph}

                                                            />
                                                            {/* <Input  /> */}
                                                        </Form.Item>
                                                        :
                                                        < p > <span>Paragraph:</span>{item.paragraph}</p>
                                                }
                                            </div>
                                    }
                                />
                            </List.Item>
                        </Form>
                    )
                    }
                />


            </div >
        )
    }
}
const EditForm = Form.create({ name: 'edit_form' })(Page_Content);

const mapStateToProps = state => ({
    state
})
const mapDispatchToProps = dispatch => bindActionCreators({
    updateSection, resetUpdateReducer
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)