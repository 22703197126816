import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Context from '../Context'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MainAppContainer from '../containers/DashboardContainer'
// import HomePage from '../pages/HomePage/HomePage'
// import Settings from '../pages/Settings'
import LoginPage from '../pages/LoginPage/LoginPage'
import Pages from "../pages/Pages/Pages"

class Routing extends Component {
    constructor() {
        super()
        this.state = {
            isLoggedIn: false
        }
    }
    static contextType = Context
    render() {
        const { isLoggedIn } = this.props.routing.loginReducer
        if (!isLoggedIn) {
            return (
                <Router>
                    <Route exact path="/" component={LoginPage} />
                </Router>
            )
        }
        return (
            <Router>
                <Switch>
                    <MainAppContainer>
                        {/* <Route exact path="/" component={HomePage} /> */}
                        <Route exact path="/" render={() => isLoggedIn ? <Pages /> : <Redirect to={{ pathname: "/" }} />} />

                        <Route exact path="/pages" render={() => isLoggedIn ? <Pages /> : <Redirect to={{ pathname: "/" }} />} />
                    </MainAppContainer>
                </Switch>
            </Router>
        )
    }
}
const mapStateToProps = state => ({
    routing: state
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Routing)