import { all, takeLatest } from "redux-saga/effects"

import { GET_REFRESH_TOKEN, GET_ALL_CONTENT, GET_NEW_SECTION, GET_RESET_UPDATE_REDUCER } from "../actions"
import { loginSaga, } from "./AuthSaga"
import { getAllContentSaga, } from "./MainSaga"
import { updateSection, resetUpdateReducer } from "./UpdateSectionSaga"

export default function* () {
    yield all([
        takeLatest(GET_ALL_CONTENT, getAllContentSaga),
        takeLatest(GET_RESET_UPDATE_REDUCER, resetUpdateReducer),
        takeLatest(GET_NEW_SECTION, updateSection),
        takeLatest(GET_REFRESH_TOKEN, loginSaga)])
}