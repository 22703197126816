import React, { Component } from 'react'
import '../../styles/index.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logOut } from "../../state/actions/AuthAction"
import { Link } from "react-router-dom"
import { Layout, Menu, Icon } from 'antd';
import "./dashboardLayout.scss"
import ReactSVG from 'react-svg'
import Logo from "../../assets/images/SVG/logo.svg"
import $ from "jquery"
const { Header, Sider, Content } = Layout;

class Dashboard extends Component {
    constructor() {
        super()
        this.state = {
            collapsed: false,

        }
    }
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    componentDidMount = () => {
        window.addEventListener("resize", this.resizeEvent);
        let page_width = $(window).width()
        if (page_width < 415) {
            console.log("callapse menu")
            this.setState({ collapsed: true })

        }
        if (page_width > 750) {
            this.setState({ collapsed: false })

        }
        // this.setState({ collapsed: false })

    }
    resizeEvent = () => {
        // const buildingImageHeight = $(".buildingImageHeight").height()
        let page_width = $(window).width()
        if (page_width < 415) {
            console.log("callapse menu", page_width)
            this.setState({ collapsed: true })

        }
        if (page_width > 750) {
            this.setState({ collapsed: false })

        }

        // this.setState({ collapsed: false })


    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeEvent);
    }

    render() {
        const { children } = this.props
        const { collapsed } = this.state
        return (
            <Layout className="dashboard_layout">
                <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
                    <div className="logo" >
                        <ReactSVG className="logo-svg" src={Logo} />
                    </div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
                        <Menu.Item key="1">
                            {/* <Icon type="user" /> */}
                            <span><Link to="/pages">Pages</Link></span>
                        </Menu.Item>

                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{ background: '#fff', padding: 0 }}>
                        <Icon
                            className="trigger"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                        />
                    </Header>
                    <Content

                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}
const mapStateToProps = state => ({
    state
})

const mapDispatchToProps = dispatch => bindActionCreators({
    logOut
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)