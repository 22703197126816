import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginAction } from "../../state/actions/AuthAction"
import { Form, Icon, Input, Button, Alert } from 'antd';
import "./login.scss"
class LoginPage extends Component {
    constructor() {
        super()
        this.state = {
            submitting: false,
            submitted: false,
            response: {
                error: false,
                response: null,
                submitted: false,
                success: false
            }
        }
    }
    handleSubmit = e => {
        this.setState({
            submitting: true
        })
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                setTimeout(() => {
                    this.props.loginAction(values)
                }, 1000);
            }

        });
    };
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.loginError.submitted) {
            this.setState({
                submitting: false,
                submitted: true,
                response: nextProps.loginError.response
            })
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { submitting, } = this.state
        const { response, submitted } = this.props.loginError
        return (
            <div className="login_page">
                <div className="login_form_container">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item >
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input

                                onChange={this.onInputChange}
                                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                            />)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    onChange={this.onInputChange}
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={submitting} className="login-form-button">
                                Log in
                             </Button>
                        </Form.Item>
                        <Form.Item>
                            {submitted ?
                                response.error ? <Alert message={response.response.message} type="error" showIcon /> : null
                                :
                                null}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}
const LoginForm = Form.create({ name: 'normal_login' })(LoginPage);
const mapStateToProps = state => ({
    loginReducer: state.loginReducer,
    loginError: state.loginError
})
const mapDispatchToProps = dispatch => bindActionCreators({
    loginAction
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)