import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Tabs, Spin } from 'antd';
import Content from "./Content"
import "./pages.scss"
const { TabPane } = Tabs;
class Pages extends Component {
    constructor() {
        super()
        this.state = {
            currentTab: 0
        }
    }
    onChageTab = (key) => {
        this.setState({ currentTab: key })
    }
    render() {
        const { response, error } = this.props.state.mainReducer
        const { currentTab } = this.state
        if (!response) {
            return <div className="loading_div">
                <Spin />
            </div>
        } else if (error) {
            return <div className="loading_div">
                {response}
            </div>
        }
        const { pages } = this.props.state.mainReducer.response.content
        const allPages = []
        Object.keys(pages).map(val => {
            return allPages.push({
                ...pages[val]
            })
        })
        const allPagesSorted = allPages.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const PageTabs = allPagesSorted.map((val, key) => {
            return <TabPane tab={val.id.toUpperCase()} key={key}>
                <Content currentTab={currentTab} page={val} />
            </TabPane>
        })
        return (
            <div id="pages_container" >
                <Tabs animated={false} defaultActiveKey="5" onChange={this.onChageTab}>
                    {PageTabs}
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    state
})
const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Pages)