import { call, put } from "redux-saga/effects"
import { SET_ALL_CONTENT } from "../actions"
import * as firebase from 'firebase/app';
import 'firebase/database';

function getContent() {
    return new Promise((resolve, reject) => {
        firebase.database().ref('/').once('value').then((snapshot) => {
            snapshot.val() ? resolve(snapshot.val()) : reject("Error getting data from database")
        })
    })
}
export function* getAllContentSaga(action) {
    try {
        const response = yield call(getContent)
        yield put({
            type: SET_ALL_CONTENT,
            response: response,
            error: false,
        })

    }
    catch (error) {
        console.log("error", error)
        yield put({
            type: SET_ALL_CONTENT,
            response: error,
            error: true,
        })
    }
}
